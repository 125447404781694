<template>
  <div class="container mescroll-touch" id="toTop">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
      <div class="top">
        <img class="menuIcon" src="@/assets/images/back_whhyf_icon@2x.png" @click="$router.go(-1)" />
        <div class="searchBox" @click="$router.push('/search')">
          <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
          <span class="searchInput">请输入关键字搜索</span>
          <!-- <input class="searchInput" type="text" placeholder="请输入关键字搜索" disabled /> -->
        </div>
      </div>
      <div class="content">
        <div class="banner">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
        </div>
        <div class="TopCategory" v-if="classifyList.length > 0">
          <span :class="{'active': SubjectTCode == item.TCcode}" v-for="(item, index) in classifyList" :key="index" @click="selectTopCategory(item.TCcode, item.Name)">{{item.Name}}</span>
        </div>
        <div class="classify">
          <div class="home_classify_item" :class="{'home_classify_item_active': ModuleType == 0}" @click="selectClassify(0)">
            <img class="screenIcon" :src="ModuleType == 0 ? require('@/assets/images/new_home_icon_alt@2x.png') : require('@/assets/images/new_home_icon_nor@2x.png')" />
            <span>最新上线</span>
          </div>
          <!-- <div class="home_classify_item" :class="{'home_classify_item_active': ModuleType == 2}" @click="selectClassify(2)">
            <img class="screenIcon" :src="ModuleType == 2 ? require('@/assets/images/good_home_icon_alt@2x.png') : require('@/assets/images/good_home_icon_nor@2x.png')" />
            <span>热门推荐</span>
          </div> -->
        </div>
        <div id="dataList">
          <waterfall :col="2" :width="itemWidth" :gutterWidth="gutterWidth" :data="list">
            <template>
              <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
                <div class="item_img">
                  <img class="item_cover" :src="item.Thumbnail" />
                  <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
                </div>
                <div class="item_content">
                  <span class="item_title">{{item.Title}}</span>
                  <p class="item_time">{{item.yyyymmdd}}</p>
                  <div class="item_nums">
                    <div class="nums">
                      <img src="@/assets/images/browse_comm_icon@2x.png" />
                      <span>{{item.WatchCount}}</span>
                    </div>
                    <div class="nums">
                      <img src="@/assets/images/like_comm_icon@2x.png" />
                      <span>{{item.LikeCount}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </waterfall>
        </div>
      </div>
    </mescroll-vue>
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import MescrollVue from 'mescroll.js/mescroll.vue'

export default {
  name: 'SSIList',
  data() {
    return {
      bannerList: [],
      classifyList: [],
      SubjectTCode: '',
      ModuleType: 0,
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        },
			},
    }
  },
  mixins: [mixin],
  components: {
    MescrollVue
  },
  computed: {
    Code() {
      return this.$route.query.Code;
    },
    TCode() {
      return this.$route.query.TCode;
    },
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  activated(){
    this.mescroll.setBounce(false);
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  created() {
    this.getBanner();
    this.getClassify();
  },
  methods: {
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      if (this.SubjectTCode !='') {
        this.getList(page.num, page.size);
      }
      // else {
      //   this.list = [];
      //   this.mescroll.resetUpScroll() // 刷新列表数据
      // }
		},
    // 获取Banner
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code='+ this.Code).then(res => {
        this.bannerList = res.Data;
      })
    },
    // 获取科室
    getClassify() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=5&pcode=' +  this.Code).then(res => {
        this.classifyList = res.Data;
        if (res.Data.length > 0) {
          this.SubjectTCode = res.Data[0].TCcode;
        } else {
          this.SubjectTCode = this.TCode;
        }
        this.list = [];
        this.mescroll.resetUpScroll() // 刷新列表数据
      })
    },
    selectTopCategory(TCcode, name) {
      this.BuriedPoint(1, name, TCcode, '/SSIList');
      this.SubjectTCode = TCcode;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    selectClassify(ModuleType) {
      this.ModuleType = ModuleType;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    // 获取列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.SubjectTCode,
          ModuleType: this.ModuleType,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  // padding: 16px 0 56px;
  box-sizing: border-box;
  background-image: url('../../assets/images/bg_comm_png@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F9F9F9;
  .mescroll {
    position: fixed;
    top: 0;
    bottom: 0;
    height: auto;
    padding-top: 16px;
  }
  .top {
    margin-bottom: 15px;
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin-left: 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .content {
    min-height: 100%;
    margin-top: 1px;
    padding: 0 12px;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
    .banner {
      border-radius: 10px;
      margin-top: 20px;
      overflow: hidden;
      .bannerImg {
        display: block;
        width: 100%;
      }
    }
    .TopCategory {
      margin-top: 18px;
      span {
        position: relative;
        font-size: 14px;
        color: #333333;
        margin-right: 20px;
      }
      .active {
        font-size: 17px;
        color: #CA001B;
        font-weight: bold;
      }
      .active::after {
        content: "";
        width: 22px;
        height: 3px;
        border-radius: 3px;
        background-color: #CA001B;
        position: absolute;
        top: 24px;
        left: 50%;
        transform: translateX(-11px);
      }
    }
    .classify {
      display: flex;
      margin-top: 24px;
      margin-bottom: 10px;
      .home_classify_item {
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 30px;
        padding: 0 10px;
        margin-right: 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #CA001B;
        border:  1px solid rgba(202, 0, 27, 0.5);
        .screenIcon {
          width: 16px;
          height: 14px;
          margin-right: 4px;
        }
      }
      .home_classify_item_active {
        color: #FFFFFF;
        background-color: #CA001B;
        border: none;
      }
    }
    .list {
      width: 100%;
      background-color: #FFFFFF;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 10px;
      .item_img {
        position: relative;
        .item_cover {
          display: block;
          width: 100%;
        }
        .playIcon {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 30px;
          height: 30px;
        }
      }
      .item_content {
        padding: 5px 8px 8px;
        .item_title {
          height: 38px;
          font-size: 14px;
          color: #333333;
          overflow: hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          word-wrap: break-word;
        }
        .item_time {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
        }
        .item_label {
          display: flex;
          flex-wrap: wrap;
          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
            padding: 0 6px;
            margin-right: 6px;
            margin-bottom: 5px;
            background-color: rgba(255, 73, 98, 0.15);
            font-size: 11px;
            color: #CA001B;
          }
        }
        .item_nums {
          display: flex;
          justify-content: flex-end;
          .nums {
            display: flex;
            align-items: center;
            img {
              width: 12px;
              margin: 0 2px 0 6px;
            }
            span {
              font-size: 11px;
              color: #666666;
            }
          }
        }
      }
    }
  }
  .content:before{
    content: '';
    display: table;
  }
}

/deep/ .van-swipe__indicators {
  top: 90%;
}
</style>